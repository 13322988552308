import axios, { AxiosInstance } from "axios";
import store from "@/store"

export default class PaymentService {
    private readonly client: AxiosInstance;

    constructor(serviceUrl: string) {
        this.client = axios.create({
            baseURL: serviceUrl,
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
    }

    async Pay(payload: any) {
        let token = ""
        await store.dispatch('session/fetchToken').then((response) => { token = response })
        const authToken = "Bearer " + token
        return this.submit("payment", authToken, payload)
    }

    async PayACH(amount: number, account: string, route: string, type: string, cust: string, invoices: Array<any>, Client: string, receipt_email_address: string, meta?: any) {
        let token = ""
        await store.dispatch('session/fetchToken').then((response) => { token = response })
        const authToken = "Bearer " + token
        let li = 0;
        invoices = invoices.map((elem: any) => {
            li++;
            return {
                arid: elem.arId,
                amount: elem.balance.toFixed(2),
                li,
                ar_app_amt: elem.balance.toFixed(2)
            }
        })
        const body = {
            Client,
            amount: amount.toFixed(2),
            route,
            account,
            type,
            invoices,
            cust,
            receipt_email_address,
            meta
        }
        return this.submit("ACHpayment", authToken, body)
        
    }

    private async submit(endpoint: string, token:string, body: Record<any, any>) {
        return new Promise((resolve, reject) => {
            this.client.post(endpoint, body,
                {
                    headers: 
                    {
                        'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
                        'Authorization': token
                    }
                })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(error => {
                    reject(error.response.data);
                })
        })
    }
}