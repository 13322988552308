import posthog from "posthog-js";
import { App } from "vue";

declare module "vue" {
  export interface ComponentCustomProperties {
    $posthog: any;
  }
}

export default {
  install(app: App) {
    const phInstance = posthog.init(process.env.VUE_APP_POSTHOG_KEY, {
      api_host: "https://us.i.posthog.com",
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
    });
    app.config.globalProperties.$posthog = phInstance;
  },
};
