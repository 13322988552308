import { Module, ActionTree, GetterTree, MutationTree, Getter } from "vuex";
import { RootState } from "@/types/state";
import SideMenuState from "@/types/state/sidemenu";

const namespaced = true;

export const state: SideMenuState = {
    collapsed: false,
    sidebarWidth: 265,
    sidebarWidthCollapsed: 38
}

export const getters: GetterTree<SideMenuState, RootState> = {
    getCollapsed(state) {
        return state.collapsed;
    },
    getWidth(state) {
        return state.sidebarWidth;
    },
    getWidthCollapsed(state) {
        return state.sidebarWidthCollapsed;
    }
}


export const mutations: MutationTree<SideMenuState> = {
    SET_COLLAPSED(state) {
        state.sidebarWidth = 0;
        state.collapsed = true;
    },
    SET_SIDEBAR_OPEN(state) {
        state.sidebarWidth = 265;
        state.collapsed = false;
    }
}

export const actions: ActionTree<SideMenuState, RootState> = {
    toggle({ commit, state }) {
        if (state.sidebarWidth == 0) {
            commit("SET_SIDEBAR_OPEN")
        } else {
            commit("SET_COLLAPSED");
        }
    },
    setCollapsed({ commit }) {
        commit("SET_COLLAPSED");
    }
}

export const sidemenu: Module<SideMenuState, RootState> = {
    namespaced,
    getters,
    state,
    mutations,
    actions
}