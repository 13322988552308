import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { RootState } from '@/types/state';
import PosState from '@/types/state/pos';
import SalesService from "@/services/SalesService";
import Utils from '@/utils/utils';
import PartsService from '@/services/PartsService';

const namespaced = true;

const orderService = new SalesService(process.env.VUE_APP_ABSTRACTION_API);
const partsService = new PartsService(process.env.VUE_APP_ABSTRACTION_API);

export const state: PosState = {
  parts: [],
  currentOrder: [],
  tenders: [],
  register: null,
  shippingAddress: null,
  taxAmount: null,
  // customer: null,
  transactionId: null,
  // user: null,
  storedQuote: null,
  lastOrderId: null,
  orderPDFs: [],
  loadingPDFs: [],
  taxLoading: false,
  poNumber: null,
  notes: null,
};

export const getters: GetterTree<PosState, RootState> = {
  getParts: (state) => {
    return state.parts;
  },
  getCurrentOrder: (state) => {
    return state.currentOrder;
  },
  getStoredQuote: (state) => {
    return state.storedQuote;
  },
  getPoNumber: (state) => {
    return state.poNumber;
  },
  getNotes: (state) => {
    return state.notes;
  },
  getRegister: (state) => {
    return state.register;
  },
  getTenders: (state) => {
    return state.tenders;
  },
  getShippingAddress: (state) => {
    return state.shippingAddress;
  },
  getTaxAmount: (state) => {
    return state.taxAmount
  },
  getTaxLoading: (state) => {
    return state.taxLoading
  },
  getTransactionId: (state) => {
    return state.transactionId;
  },
  getLastOrderId: (state) => {
    return state.lastOrderId;
  },
  getPDFS: (state) => {
    return state.orderPDFs;
  },
  getLoadingPDFs: (state) => {
    return state.loadingPDFs;
  }
};

export const mutations: MutationTree<PosState> = {
  SET_PARTS(state, parts) {
    state.parts = [...parts];
  },
  SET_PO_NUMBER(state, number) {
    state.poNumber = number;
  },
  SET_NOTES(state, notes) {
    state.notes = notes;
  },
  CLEAR_PARTS(state) {
    state.parts = [];
  },
  SET_STORED_QUOTE(state, quote) {
    state.storedQuote = quote
  },
  CLEAR_ORDER(state) {
    state.currentOrder = [];
  },
  ADD_TO_ORDER(state, part) {
    part.amount = Number(part.amount);
    state.currentOrder
      ? state.currentOrder.push(part)
      : (state.currentOrder = [part]);
  },
  UPDATE_PART_QTY(state, { partIndex, part }) {
    state.currentOrder[partIndex] = part;
  },
  UPDATE_AR_AMT(state, { arIndex, ar }) {
    state.currentOrder[arIndex] = ar;
  },
  REMOVE_FROM_ORDER(state, partIndex) {
    state.currentOrder.splice(partIndex, 1);
  },
  SET_REGISTER(state, reg) {
    state.register = reg;
  },

  ADD_TO_TENDERS(state, tender) {
    state.tenders ? state.tenders.push(tender) : (state.tenders = [tender]);
  },
  UPDATE_TENDER_AMT(state, { tenderIndex, tender }) {
    state.tenders[tenderIndex] = tender;
  },
  REMOVE_TENDER(state, tenderIndex) {
    state.tenders.splice(tenderIndex, 1);
  },
  CLEAR_TENDERS(state) {
    state.tenders = [];
  },
  ADD_OA_LINE(state, overage) {
    const li = state.tenders[0].li_items.length + 1;
    state.tenders[0].li_items.push({
      li: li.toString(),
      type: 'OA',
      ar_app_amt: overage,
    });
  },
  ADD_CHANGE(state, overage) {
    state.tenders[0].change_amt = overage;
  },
  SET_SHIPPING_ADDRESS(state, address) {
    state.shippingAddress = address;
  },
  CLEAR_SHIPPING_ADDRESS(state) {
    state.shippingAddress = null;
  },
  SET_TAX_AMOUNT(state, amount) {
    state.taxAmount = amount;
  },
  SET_TAX_LOADING(state, loading) {
    state.taxLoading = loading;
  },
  CLEAR_TAX_AMOUNT(state) {
    state.taxAmount = null;
  },
  SET_TRANSACTION_ID(state, id) {
    state.transactionId = id;
  },
  SET_LAST_ORDER_ID(state, id) {
    state.lastOrderId = id;
  },
  ADD_PDF(state, pdf) {
    state.orderPDFs.push(pdf);
  },
  REMOVE_PDF_BY_ID(state, id) {
    state.orderPDFs = state.orderPDFs.filter((item: any) => item.id !== id);
  },
  ADD_PDF_LOADING(state, id) {
    state.loadingPDFs.push(id);
  },
  REMOVE_PDF_LOADING(state, id) {
    state.loadingPDFs = state.loadingPDFs.filter((item) => item !== id);
  },
};

export const actions: ActionTree<PosState, RootState> = {
  fetchParts({ commit, dispatch }, { selection }) {
    return new Promise((resolve, reject) => {
      partsService
        .getPartsBySelection(selection)
        .then((response: any) => {
          commit('SET_PARTS', response.parts_items);
          resolve({ success: true });
        })
        .catch((error: any) => {
          dispatch(
            'notification/add',
            {
              message: `Error Displaying Parts. ${error.message}.`,
              type: 'error',
            },
            { root: true }
          );
          reject({ success: false, error: error });
        });
    });
  },
  fetchPrices({ dispatch }, { client, code, cat, part_no, web_cats, customerId, rangeStart, rangeEnd }) {
    return new Promise((resolve, reject) => {
      partsService
        .getPrices(client, part_no, cat, code, web_cats, customerId, rangeStart.toString(), rangeEnd.toString())
        .then((response: any) => {
          resolve({ success: true, parts: response.price_items, filters: response.control_items?.find((x:any)=>x!==undefined)?.web_category_items});
        })
        .catch((error: any) => {
          dispatch(
            "notification/add",
            {
              message: `Error Displaying Parts. ${error.message}.`,
              type: "error",
            },
            { root: true }
          );
          reject({ success: false, error: error });
        });
    });
  },
  clearRegister({ commit }) {
    commit('SET_REGISTER', null);
  },
  clearOrder({ commit }) {
    commit('CLEAR_ORDER');
  },
  addPartToOrder({ commit }, part) {
    commit('ADD_TO_ORDER', part);
  },
  updatePartQty({ commit }, { partIndex, part }) {
    commit('UPDATE_PART_QTY', { partIndex, part });
  },
  setStoredQuote({commit}, quote) {
    commit('SET_STORED_QUOTE', quote)
  },
  updateInvoiceAmount({ commit }, { arIndex, ar }) {
    commit('UPDATE_AR_AMT', { arIndex, ar });
  },
  setPoNumber({commit}, number) {
    commit('SET_PO_NUMBER', number)
  },
  setNotes({ commit }, notes) {
    commit('SET_NOTES', notes);
  },
  removeFromOrder({ commit }, index) {
    commit('REMOVE_FROM_ORDER', index);
  },
  setUser({ commit }, user) {
    commit('SET_USER', user);
  },
  clearTenders({ commit }) {
    commit('CLEAR_TENDERS');
  },
  addTender({ commit }, tender) {
    commit('ADD_TO_TENDERS', tender);
  },
  removeTender({ commit }, index) {
    commit('REMOVE_TENDER', index);
  },
  updateTenderAmount({ commit }, { tenderIndex, tender }) {
    commit('UPDATE_TENDER_AMT', { tenderIndex, tender });
  },
  addOaLine({ commit }, overage) {
    commit('ADD_OA_LINE', overage);
  },
  addChange({ commit }, overage) {
    commit('ADD_CHANGE', overage);
  },
  setShippingAddress({commit}, address) {
    commit("SET_SHIPPING_ADDRESS", address)
  },
  removeShippingAddress({commit}) {
    commit("CLEAR_SHIPPING_ADDRESS")
  },
  setTaxAmount({commit}, amount) {
    commit("SET_TAX_AMOUNT", amount)
  },
  setLoadingTax({commit}, loading) {
    commit("SET_TAX_LOADING", loading)
  },
  clearTaxAmount({commit}) {
    commit("CLEAR_TAX_AMOUNT")
  },
  setTransactionId({commit}, id) {
    commit("SET_TRANSACTION_ID", id)
  },
  clearPOS({commit, state}) {
    commit("CLEAR_PARTS")
    commit("CLEAR_ORDER")
    commit("CLEAR_TENDERS")
    commit("CLEAR_SHIPPING_ADDRESS")
    commit("CLEAR_TAX_AMOUNT")
    commit("SET_TRANSACTION_ID", null)
    commit("REMOVE_PDF_BY_ID", state.lastOrderId)
    commit("SET_LAST_ORDER_ID", null)
  },
  setLastOrderId({commit}, id) {
    commit("SET_LAST_ORDER_ID", id)
  },
  clearLastOrderId({commit}) {
    commit("SET_LAST_ORDER_ID", null)
  },
  async getOrderPDF({ commit, dispatch, state }, payload) {
    if(!payload.email) {
      const pdf = state.orderPDFs.find(
        (i: any) => i.id === payload.recordId
      );
      if (pdf?.pdf) {
        window.open(pdf.pdf, "_blank");
      } else {
        commit("ADD_PDF_LOADING", payload.recordId);
        await orderService
          .getOrderPDF(payload.recordId)
          .then((resp: any) => {
            const bufferArray = Utils.base64ToArrayBuffer(resp);
            const blobStore = new Blob([bufferArray], {
              type: "application/pdf",
            });
            const data = window.URL.createObjectURL(blobStore);
            window.open(data, "_blank");
            commit("ADD_PDF", { id: payload.recordId, pdf: data });
            commit("REMOVE_PDF_LOADING", payload.recordId);
          })
          .catch((error) => {
            const notification = {
              type: "error",
              message: error + " .Please try again.",
            };
            dispatch("notification/add", notification, { root: true });
            commit("REMOVE_PDF_LOADING", payload.recordId);
          });
      }
    } else {
      await orderService
          .getOrderPDF(payload.recordId, payload.email)
          .then(() => {
            const notification = {
              type: "success",
              message: "Order " +payload.recordId+ " has been sent to " + payload.email,
            };
            dispatch("notification/add", notification, { root: true });
          })
          .catch((error) => {
            const notification = {
              type: "error",
              message: "Email could not be sent. " + error,
            };
            dispatch("notification/add", notification, { root: true });
            commit("REMOVE_PDF_LOADING", payload.recordId);
          });
    }
    
  }, 
};

export const pos: Module<PosState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
