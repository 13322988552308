import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-726427fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full grid mr-0 justify-content-center pt-3 top-statcards-wrapper" }
const _hoisted_2 = { class: "col-11 grid justify-content-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatCard = _resolveComponent("StatCard")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.env.modules.includes('Invoices') || _ctx.env.modules.length == 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["xl:col-4 xl:block col-12", {'block': _ctx.$route.fullPath?.toString().includes('invoices')}])
          }, [
            _createVNode(_component_router_link, {
              to: "/invoices",
              class: "no-underline"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_StatCard, {
                  selected: _ctx.$route.fullPath?.toString().includes('invoices'),
                  title: "Invoices",
                  subtitle: "Amount Due",
                  getter: "invoice/getMonthlyData",
                  setter: "invoice/fetchMonthlyData",
                  amountArray: _ctx.invoicesMonthlyData
                }, null, 8, ["selected", "amountArray"])
              ]),
              _: 1
            })
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.env.modules.includes('Quotes'))
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["xl:col-4 xl:block col-12", {'block': _ctx.$route.fullPath?.toString().includes('tickets')}])
          }, [
            _createVNode(_component_router_link, {
              to: "/quotes",
              class: "no-underline"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_StatCard, {
                  selected: _ctx.$route.fullPath?.toString().includes('quotes'),
                  title: "Quotes",
                  subtitle: "Quotes Created",
                  getter: "sales/getQuotesMonthlyData",
                  setter: "sales/fetchQuotesMonthlyData",
                  amountArray: _ctx.quotesData
                }, null, 8, ["selected", "amountArray"])
              ]),
              _: 1
            })
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.env.modules.includes('Tickets') || _ctx.env.modules.length == 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["xl:col-4 xl:block col-12", {'block': _ctx.$route.fullPath?.toString().includes('tickets')}])
          }, [
            _createVNode(_component_router_link, {
              to: "/tickets",
              class: "no-underline"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_StatCard, {
                  selected: _ctx.$route.fullPath?.toString().includes('tickets'),
                  title: "Tickets",
                  subtitle: "Tickets Created",
                  getter: "billing/getMonthlyData",
                  setter: "billing/fetchMonthlyData",
                  amountArray: _ctx.ticketsMonthlyData
                }, null, 8, ["selected", "amountArray"])
              ]),
              _: 1
            })
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.env.modules.includes('Sales') || _ctx.env.modules.length == 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass(["xl:col-4 xl:block col-12", {'block': _ctx.$route.fullPath?.toString().includes('sales')}])
          }, [
            _createVNode(_component_router_link, {
              to: "/sales",
              class: "no-underline"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_StatCard, {
                  selected: _ctx.$route.fullPath?.toString().includes('sales'),
                  title: "Orders",
                  subtitle: "Orders Placed",
                  getter: "sales/getSoMonthlyData",
                  setter: "sales/fetchSoMonthlyData",
                  amountArray: _ctx.ordersMonthlyData
                }, null, 8, ["selected", "amountArray"])
              ]),
              _: 1
            })
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}