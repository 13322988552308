import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store"
import qs from "qs";
export default class SalesService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getOrders(custId: string, id: string, status: string) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    const params = {
      cust: custId,
      id,
      status,
    };
    return this.submit(`sales/orders`, authToken, params);
  }
  
  async getKpiInfo(cust: string, month: string, year: string) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    const params = {
      cust,
      month,
      year,
    };
    return this.submit(`sales/orders/kpi`, authToken, params);
  }
  async getOrdersRecords(custId: string, id: string, status: string, rangeStart: number, rangeEnd: number, correls: string, sortBy: string, sortOrder: string) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    const params = {
      cust: custId,
      ids : id,
      status,
      rangeStart,
      rangeEnd,
      correls,
      sortBy,
      sortOrder,
    };
    return this.submit(`sales/orders`, authToken, params);
  }

  async getOrderPDF(id: string, email?: string) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    const params = {
        email
    }
    return this.submit(`sales/orders/${id}/pdf`, authToken, params)
  }

  async postOrder(payload: any) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token

    return this.submitPost(`sales/orders`, authToken, payload)
  }

  async putOrder(orderId: string, payload: any) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    return this.submitPut(`sales/orders/${orderId}`, authToken, payload)
  }

  private async submit(endpoint: string, token: string, params: any) {
    return new Promise((resolve, reject) => {
      this.client
        .get(endpoint, {
          params,
          paramsSerializer: params => {
            return qs.stringify(params)
          },
          headers:
          {
              'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
              'Authorization': token
          }
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private async submitPost(endpoint: string, token: string, payload: any) {
    return new Promise((resolve, reject) => {
      this.client.post(endpoint, payload, { 
        headers:
        {
            'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
            'Authorization': token
        }
       })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  private async submitPut(endpoint: string, token: string, payload: any) {
    return new Promise((resolve, reject) => {
      this.client.put(endpoint, payload, { 
        headers:
        {
            'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
            'Authorization': token
        }
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }
}
