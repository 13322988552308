import { AxiosInstance } from "axios";
import axios from "axios";
import qs from "qs";
import store from "../store"

export default class TicketService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async newTicket(payload: any) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token

    return this.submitPost(`tickets`, authToken, payload);
  }

  async getTickets(
    rangeStart: any,
    rangeEnd: any,
    cust: any,
    ids: any,
    status: any,
    types: any,
    sortOrder: any,
    sortBy: any
  ) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    const params = {
      rangeStart,
      rangeEnd,
      cust,
      ids,
      status,
      types,
      sortOrder,
      sortBy
    };
    return this.submitGet(`tickets`, authToken, params);
  }

  async getKpiInfo(cust: string, month: string, year: string) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    const params = {
      cust,
      month,
      year,
    };
    return this.submitGet(`tickets/kpi`, authToken, params);
  }

  async updateTicket(payload: any) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    const { ticket_id, newTicket, oldTicket } = payload;

    return this.submitPut(`tickets/${ticket_id}`, authToken, {newTicket, oldTicket});
  }

  private async submitPost(
    endpoint: string,
    token: string,
    payload: any,
    params?: any
  ) {
    return new Promise((resolve, reject) => {
      this.client
        .post(endpoint, payload, { 
          params,
          headers:
          {
              'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
              'Authorization': token
          }
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  private async submitGet(endpoint: string, token: string, params: any) {
    return new Promise((resolve, reject) => {
      this.client
        .get(endpoint, { 
          params,
          paramsSerializer: params => {
            return qs.stringify(params)
          },
          headers:
          {
              'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
              'Authorization': token
          }
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  private async submitPut(
    endpoint: string,
    token: string,
    payload: any,
    params?: any
  ) {
    return new Promise((resolve, reject) => {
      this.client
        .put(endpoint, payload, { 
          params,
          headers:
          {
              'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
              'Authorization': token
          }
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}
