import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import EnvState from "@/types/state/env";
import EnvService from "@/services/EnvService";

const namespaced = true;

const envService = new EnvService(process.env.VUE_APP_ABSTRACTION_API);

export const state: EnvState = {
  modules: [],
};

export const getters: GetterTree<EnvState, RootState> = {
  getModules(state): any {
    return state.modules;
  },
};

export const mutations: MutationTree<EnvState> = {
  //to change the state
  SET_MODULES(state, modules) {
    state.modules = modules.filter((module:any) => module.includes('Portal')).map((module:any) => module.replace('Portal_', ''));
  },
};

export const actions: ActionTree<EnvState, RootState> = {
  
  fetchModules({commit}) {
    return new Promise((resolve, reject) => {
     envService
      .fetchModules()
      .then((response:any) => {
        commit("SET_MODULES", response.data.modules);
        resolve(response.data.modules);
      })
      .catch((error) => {
        reject({ success: false, error: error });
      });
    });
  },
}

export const env: Module<EnvState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
