import { AxiosInstance } from "axios";
import axios from "axios";
import qs from "qs";
import store from "../store"

export default class EnvService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async fetchModules() {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    return this.submitGet(`restrictedTo`, authToken);
  }

  private async submitGet(endpoint: string, token: string) {
    return new Promise((resolve, reject) => {
      this.client
        .get(endpoint, { 
          headers:
          {
              'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
              'Authorization': token
          }
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}
