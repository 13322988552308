import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store"
import qs from "qs";
export default class InvoiceService {
    private readonly client: AxiosInstance;
    constructor(serviceUrl: string) {
        this.client = axios.create({
            baseURL: serviceUrl,
            withCredentials: false
        })
    }

    async setInvoices(rangeStart: number, rangeEnd: number, custId: string, dateStart?: string, dateEnd?: string, status?: string, sortBy?: string, sortOrder?: any, id?: string, type?: string[]) {
        let token = ""
        await store.dispatch('session/fetchToken').then((response) => { token = response })
        const authToken = "Bearer " + token
        const params = {
            rangeStart,
            rangeEnd,
            dateStart,
            dateEnd,
            status,
            sortBy,
            sortOrder,
            custId,
            id,
            type
        }
        return this.submit(`invoices`, authToken, params)
    }

    async getInvoice(key: string) {
        let token = ""
        await store.dispatch('session/fetchToken').then((response) => { token = response })
        const authToken = "Bearer " + token
        return this.submitInvoice(`invoices/${key}/pdf`, authToken)
    }

    async getKpiInfo(cust: string, month: string, year: string) {
        let token = ""
        await store.dispatch('session/fetchToken').then((response) => { token = response })
        const authToken = "Bearer " + token
        const params = {
          cust,
          month,
          year,
        };
        return this.submitGet(`invoices/kpi`, authToken, params);
      }

    // Helpers
    private async submit(endpoint: string, token: string, params?: any) {
        return new Promise((resolve, reject) => {
            this.client
                .get(endpoint, 
                    { 
                        params,
                        paramsSerializer: params => {
                            return qs.stringify(params)
                          },
                        headers: 
                        {
                            'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
                            'Authorization': token
                        }
                    })
                .then(response => {
                    resolve(response.data.ar_items)
                })
                .catch(error => reject(error))
        });
    }

    private async submitGet(endpoint: string, token: string, params?: any) {
        return new Promise((resolve, reject) => {
            this.client
                .get(endpoint, 
                    { 
                        params,
                        headers: 
                        {
                            'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
                            'Authorization': token
                        }
                    })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => reject(error))
        });
    }


    private async submitInvoice(endpoint: string, token: string, params?: any) {
        return new Promise((resolve, reject) => {
            this.client.get(endpoint, 
                { 
                    params,
                    headers: 
                    {
                        'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
                        'Authorization': token
                    } 
                })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(error => {
                    reject(error)
                });
        })
    }
}