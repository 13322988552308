import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";

export default class ContactService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getContact(id: string | null) {
    let token = "";
    await store.dispatch("session/fetchToken").then((response) => {
      token = response;
    });
    const authToken = "Bearer " + token;
    const params = {
      ids: id,
    };

    return this.submitGet(`/contacts`, authToken, params);
  }

  async putContact(contact: any, oldContact: any) {
    let token = "";
    await store.dispatch("session/fetchToken").then((response) => {
      token = response;
    });
    const authToken = "Bearer " + token;

    const body = {
      contact,
      oldContact,
    };
    return this.submitPut(`contacts`, authToken, body);
  }

  async updateContact(oldContact: any, contact: any) {
    let token = "";
    store.dispatch("session/fetchToken").then((response) => {
      token = response;
    });
    const authToken = "Bearer " + token;
    const payload = {
      oldContact,
      contact,
    };
    return this.submitPut(`contacts`, authToken, payload);
  }

  private async submitGet(endpoint: string, token: string, params: any) {
    return new Promise((resolve, reject) => {
      this.client
        .get(endpoint, {
          params,
          headers: {
            "Ocp-Apim-Subscription-Key": store.getters["session/getSubKey"],
            'Authorization': token,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private async submitPost(endpoint: string, token: string, payload: any, params?: any) {
    return new Promise((resolve, reject) => {
      this.client
        .post(endpoint, payload, {
          params,
          headers: {
            "Ocp-Apim-Subscription-Key": store.getters["session/getSubKey"],
            Authorization: token,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  }

  private async submitPut(endpoint: string, token: string, payload: any) {
    return new Promise((resolve, reject) => {
      this.client
        .put(endpoint, payload, {
          headers: {
            "Ocp-Apim-Subscription-Key": store.getters["session/getSubKey"],
            Authorization: token,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  }
}
