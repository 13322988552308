import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store"
import qs from "qs";

export default class SOQuotesService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getSOQuotes(payload: any) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    return this.submit(`sales/quotes`, authToken, payload)
    .catch(error => {
      store.dispatch('notification/add', {
          message: `Failed To Get The Quotes: ${error?.response?.data?.error || error?.response?.data?.message || error?.error || error?.message || ''}`,
          type: "error",
      });
    });
  }
  async postSOQuote(payload: any) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    return this.submitPost(`sales/quotes`, authToken, payload);
  }
  async SOQuotePdf (id: string, email?: string, modelNo?: string, prices?: string) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    const params = {
      id,
      email,
      modelNo,
      prices
    };
    return this.submit(`sales/quotes/${id}/pdf`, authToken, params)
    .catch(error => {
      store.dispatch('notification/add', {
          message: `Failed To Send Quote PDF: ${error?.response?.data?.error || error?.response?.data?.message || error?.error || error?.message || ''}`,
          type: "error",
      });
    });
  }
  async putSOQuote(payload: any) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const { quoteId, newQuote, oldQuote } = payload;
    const authToken = "Bearer " + token
    return this.submitPut(`sales/quotes/${quoteId}`, authToken, { newQuote, oldQuote });
  }

  private async submit(endpoint: string, token: string, params?: any) {
    return new Promise((resolve, reject) => {
      this.client
        .get(endpoint, {
          params,
          paramsSerializer: params => {
            return qs.stringify(params)
          },
          headers:
          {
              'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
              'Authorization': token
          }
        })
        .then((resp) => {
          if (resp.status == 204) {
            resp.data = [];
          }
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  private async submitPost(endpoint: string, token: string, payload: any, params?: any) {
    return new Promise((resolve, reject) => {
      this.client.post(endpoint, payload, { 
        params,
        headers:
        {
            'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
            'Authorization': token
        }
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }

  private async submitPut(endpoint: string, token: string, payload: any, params?: any) {
    return new Promise((resolve, reject) => {
      this.client.put(endpoint, payload, { 
        params,
        headers:
        {
            'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
            'Authorization': token
        }
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }
}
