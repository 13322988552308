import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import TicketState from "@/types/state/billing";
import TicketService from "@/services/TicketService";
import ControlService from "@/services/ControlService";

const namespaced = true;
const ticketService = new TicketService(process.env.VUE_APP_ABSTRACTION_API);
const controlService = new ControlService(process.env.VUE_APP_ABSTRACTION_API);

export const state: TicketState = {
  tickets: [],
  billingTypeItems: [],
  userIdItems: [],
  custTypeItems: [],
  page: 0,
  rangeStart: 1,
  rangeEnd: 100,
  currentIndex: -1,
  monthlyData: [],
};

export const getters: GetterTree<TicketState, RootState> = {
  getTickets: (state) => {
    return state.tickets;
  },
  getMonthlyData: (state) => {
    return state.monthlyData
  },
  getRange(state): number {
    return state.rangeEnd;
  },
  getPage(state): number {
    return state.page;
  },
  getLength(state): number {
    return state.tickets.length;
  },
  getCurrentIndex(state): number {
    return state.currentIndex;
  },
  getBillingTypeItems: (state) => {
    return state.billingTypeItems;
  },

  getUserIdItems: (state) => {
    return state.userIdItems;
  },
  getCustTypeItems: (state) => {
    return state.custTypeItems;
  },
};

export const mutations: MutationTree<TicketState> = {
  SET_TICKETS(state, tickets) {
    state.tickets.push(...tickets);
  },
  SET_MONTHLY_DATA(state, data) {
    state.monthlyData = data
  },
  SET_BILLING_DATA(state, { billingData }) {
    state.billingTypeItems = billingData;
  },
  CLEAR_TICKETS(state) {
    state.tickets = [];
  },
  NEXT_PAGE(state, page) {
    state.page = page;
  },
  NEXT_RANGE(state) {
    state.rangeStart += 100;
    state.rangeEnd += 100;
  },
  RESET_PAGE(state) {
    state.page = 0;
    state.rangeStart = 1;
    state.rangeEnd = 100;
  },
  SET_CURR_INDEX(state, index){
    state.currentIndex = index;
  },
  UPDATE_TICKET(state, ticket){
    const ticketIndex = state.tickets.findIndex((existingTicket) => {
      return existingTicket.id == ticket.id
    })
    state.tickets[ticketIndex] = ticket;
  }
};

export const actions: ActionTree<TicketState, RootState> = {
  setNextPage({commit},page){
    commit("NEXT_PAGE",page);
  },
  updateCurrentIndex({ commit }, index) {
    commit("SET_CURR_INDEX", index);
  },
  updateTicketRecord({ commit }, ticket) {
    commit("UPDATE_TICKET", ticket);
  },
  async fetchMonthlyData({ commit}, {cust, month, year}) {
      ticketService
        .getKpiInfo(cust, month, year)
        .then((response: any) => {
          commit("SET_MONTHLY_DATA", response.data.data);
        })
        .catch((error) => {
          console.log(error)
        });
  }, 
  async fetchTickets({ commit, state }, { cust, ids, status, addTicket, types, sortOrder, sortBy }) {
    if (!addTicket) {
      commit("RESET_PAGE");
    }
    return new Promise((resolve, reject) => {
      ticketService
        .getTickets(state.rangeStart, state.rangeEnd, cust, ids, status,types, sortOrder, sortBy)
        .then((response: any) => {
          if (!addTicket) {
            commit("CLEAR_TICKETS");
          }
          resolve({ success: true, data: response.data.ticket_items });
          commit("NEXT_RANGE");
        })
        .catch((error) => {
          reject({ success: false, error: error });
        });
    });
  },
  async getControls({ commit, dispatch }, payload) {
    if (payload.fieldnames === null) {
      payload.fieldnames = "";
    }
    await controlService
      .getControl(
        payload.id,
        payload.procedure,
        payload.filename,
        payload.fieldnames,
      )
      .then((response: any) => {
        switch (payload.id) {
          case "BILL":
            commit("SET_BILLING_DATA", {
              billingData: response[0].billing_type_items,
            });
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        dispatch(
          "notification/add",
          {
            type: "error",
            message: 'Failed to Load controls',
          },
          { root: true }
        );
      });
  },
};

export const billing: Module<TicketState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
