import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import SalesState from "@/types/state/sales";

import SalesService from "@/services/SalesService";
import ControlService from "@/services/ControlService";
import SalesItems from "@/types/Responses/sales";
import ContactControl from "@/types/Controls/contactControl";
import SOQuotePDfView from "@/types/soquotepdf";
import SoQuotesService from "@/services/SOQuotesService";

const orderService = new SalesService(process.env.VUE_APP_ABSTRACTION_API);
const controlService = new ControlService(process.env.VUE_APP_ABSTRACTION_API);
const soquotesService = new SoQuotesService(process.env.VUE_APP_ABSTRACTION_API);

const namespaced = true;

export const state: SalesState = {
  itemsPerPage: 5,
  page: 1,
  firstRow: 0,
  rangeStart: 1,
  rangeEnd: 100,
  types: [],
  quotePDFs: [],
  loadingQuotes: [],
  quoteToEdit: null,
  soMonthlyData: [],
  quoteMonthlyData: []
};

export const getters: GetterTree<SalesState, RootState> = {
  getQuoteToEdit: (state) => {
    return state.quoteToEdit;
  },
  getQuotesMonthlyData: (state) => {
    return state.quoteMonthlyData;
  },
  getSoMonthlyData: (state) => {
    return state.soMonthlyData;
  },
  getRangeEnd: (state) => {
    return state.rangeEnd;
  },
  getPage: (state) => {
    return state.page;
  },
  getFirstRow: (state) => {
    return state.firstRow;
  },
  getTypes: (state) => {
    return state.types;
  },
  getQuotePDF(state): Array<SOQuotePDfView> {
    return state.quotePDFs;
  },
  getLoadingQuotes(state) {
    return state.loadingQuotes;
  },
};

export const mutations: MutationTree<SalesState> = {
  SET_TYPES(state, types) {
    state.types = types;
  },
  SET_QUOTE_TO_EDIT(state, quote) {
    state.quoteToEdit = quote;
  },
  SET_SO_MONTHLY_DATA(state, data) {
    state.soMonthlyData = data
  },
  SET_QUOTE_MONTHLY_DATA(state, data) {
    state.quoteMonthlyData = data
  },
  CLEAR_QUOTE_TO_EDIT(state) {
    state.quoteToEdit = null;
  },
  NEXT_PAGE(state, page) {
    state.page = page;
  },
  NEXT_RANGE(state) {
    state.rangeStart += 100;
    state.rangeEnd += 100;
  },
  SET_ITEMS_PER_PAGE(state, items) {
    state.itemsPerPage = items;
  },
  SET_FIRST_ROW(state, row) {
    state.firstRow = row;
  },
  RESET_PAGE(state) {
    state.page = 1;
    state.rangeStart = 1;
    state.rangeEnd = 100;
  },
  ADD_ID_LOADING(state, id) {
    state.loadingQuotes.push(id);
  },
  SET_SO_QUOTES_PDF(state, obj) {
    state.quotePDFs.push(obj);
  },
  REPLACE_SO_QUOTES_PDF(state, obj) {
    obj.id
    const index = state.quotePDFs.indexOf(obj.id, 0);
    if (index > -1) {
      state.quotePDFs.splice(index, 1);
      state.quotePDFs.push(obj);
    }
  },
  REMOVE_ID_LOADING(state, id) {
    const index = state.loadingQuotes.indexOf(id, 0);
    if (index > -1) {
      state.loadingQuotes.splice(index, 1);
    }
  },
};

export const actions: ActionTree<SalesState, RootState> = {
  setQuoteToEdit({commit}, quote) {
    commit("SET_QUOTE_TO_EDIT", quote);
  },
  clearQuoteToEdit({commit}) {
    commit("CLEAR_QUOTE_TO_EDIT");
  },
  setFirstRow({commit}, row){
    commit("SET_FIRST_ROW", row);
  },
  async fetchSoMonthlyData({ commit}, {cust, month, year}) {
      orderService
        .getKpiInfo(cust, month, year)
        .then((response: any) => {
          commit("SET_SO_MONTHLY_DATA", response.data);
        })
        .catch((error) => {
          console.log(error)
        });
  }, 
  fetchOrders({ state, commit, dispatch }, { custId, id, status}) {
    return new Promise((resolve, reject) => {
      orderService
        .getOrders(custId, id, status)
        .then((response) => {
          resolve({ so_items: (response as SalesItems).so_items, success: true });
        })
        .catch((error) => {
          dispatch(
            "notification/add",
            {
              message: `Error Updating Quote. ${error.message}.`,
              type: "error",
            },
            { root: true }
          );
          reject({ success: false, error: error });
        });
    });
    
  },
  fetchOrdersRecords({ state, commit, dispatch }, { custId, id, status, addOrder, correls, sortBy, sortOrder }) {
    return new Promise((resolve, reject) => {
      if(!addOrder){
        commit("RESET_PAGE");
      }
      orderService
        .getOrdersRecords(custId, id, status, state.rangeStart, state.rangeEnd, correls, sortBy, sortOrder)
        .then((response) => {
          if(!addOrder){
            commit("SET_FIRST_ROW", 0);
          }
          commit("NEXT_RANGE")
          resolve({ so_items: (response as SalesItems).so_items, success: true });
        })
        .catch((error) => {
          dispatch(
            "notification/add",
            {
              message: `Error Updating Quote. ${error.message}.`,
              type: "error",
            },
            { root: true }
          );
          reject({ success: false, error: error });
        });
    });
  },
  fetchControls({ commit, dispatch }, payload) {
    if (payload.fieldnames == null) {
      payload.fieldnames = "";
    }
    controlService
      .getControl(
        payload.id,
        payload.procedure,
        payload.filename,
        payload.fieldnames,
      )
      .then((response) => {
        if ((payload.id as string).startsWith("MRK")) {
          payload.id = "MRK";
        }
        switch (payload.id) {
          case "SALE":
            break;
          case "MRK":
            commit("SET_TYPES", (response as ContactControl).valid_so_type_items);
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        dispatch(
          "notification/add",
          {
            type: "error",
            message: error,
          },
          { root: true }
        );
      });
  },
  async fetchQuotePDF({ commit, dispatch, state }, payload) {
    const pdf = state.quotePDFs.find(
      (quote: SOQuotePDfView) => quote.id === payload.recordId
    );
    if (pdf !== undefined && !payload.modelNo && !payload.forceFetch) {
      window.open(pdf.pdf, "_blank");
    } else {
      commit("ADD_ID_LOADING", payload.recordId);
      await soquotesService
        .SOQuotePdf(payload.recordId, "", payload.modelNo, payload.prices)
        .then((resp: any) => {
          const bufferArray = base64ToArrayBuffer(resp);
          const blobStore = new Blob([bufferArray], {
            type: "application/pdf",
          });
          const data = window.URL.createObjectURL(blobStore);
          window.open(data, "_blank");  
          if(pdf !== undefined) {
            commit("REPLACE_SO_QUOTES_PDF", {id: payload.recordId, pdf: data })
          }
          else {
            commit("SET_SO_QUOTES_PDF", { id: payload.recordId, pdf: data });
          }
          commit("REMOVE_ID_LOADING", payload.recordId)
        })
        .catch((error) => {
          commit("REMOVE_ID_LOADING", payload.recordId);
        });
    }
  },
}
export const sales: Module<SalesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

function base64ToArrayBuffer(data: string) {
  const bString = window.atob(data);
  const bLength = bString.length;
  const bytes = new Uint8Array(bLength);
  for (let i = 0; i < bLength; i++) {
    const ascii = bString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

