import { AxiosInstance } from "axios";
import axios from "axios";
import PaymentInfo from "@/types/paymentinfo";
import store from "../store"

export default class ControlService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getControl(id: string, procedure: string, filename: string, fieldnames: string, Client?: string) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    const params = {
        id,
        procedure,
        filename,
        fieldnames,
    }
    return this.submit(`control`, authToken, params)
}

  async getPaymentInfo(option: string) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    const params = {
      option,
    };
    return this.submit(`payment/info`, authToken, params);
  }

  private async submit(endpoint: string, token: string, params: any) {
    return new Promise((resolve, reject) => {
      this.client
        .get(endpoint, 
          { 
            params,
            headers: 
            {
              'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
              'Authorization': token
            }
          })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  }
}
