import { AxiosInstance } from "axios";
import axios from 'axios';
import store from "../store"

export default class TermsService {
    private readonly client: AxiosInstance;
    constructor(serviceUrl: string) {
        this.client = axios.create({
            baseURL: serviceUrl,
            withCredentials: false,
        })
    }

    async getTerms() {
        let token = ""
        await store.dispatch('session/fetchToken').then((response) => { token = response })
        const authToken = "Bearer " + token
        return this.submit(`terms`, authToken);
    }
    private async submit(endpoint: string, token: string) {
        return new Promise((resolve, reject) => {
            this.client
                .get(endpoint, {
                    headers:
                    {
                        'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
                        'Authorization': token
                    }
                })
                .then((response) => {
                    resolve(response);
                })
                .catch(error => reject(error))
        });

    }

}