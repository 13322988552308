import { Module, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import NotificationState from "@/types/state/notification";

const namespaced = true;
let nextId = 1;

export const state: NotificationState = {
    notifications: []
};

export const mutations: MutationTree<NotificationState> = {
    PUSH(state, notification) {
        state.notifications.push({
            ...notification,
            id: nextId++
        });
    },
    DELETE(state, notificationToRemove) {
        state.notifications = state.notifications.filter(
            notification => notification.id !== notificationToRemove.id
        );
    }
};

export const actions: ActionTree<NotificationState, RootState> = {
    add({ commit }, notification) {
        commit("PUSH", notification);
    },
    remove({ commit }, notificationToRemove) {
        commit("DELETE", notificationToRemove);
    }
};

export const notification: Module<NotificationState, RootState> = {
    namespaced,
    state,
    actions,
    mutations
};
