import { AxiosInstance } from "axios";
import axios from 'axios';
import store from "../store"

export default class StaxService {
    private readonly client: AxiosInstance;
    constructor(serviceUrl: string) {
        this.client = axios.create({
            baseURL: serviceUrl,
            withCredentials: false,
        })
    }

    async getStax(client: any) {
        let token = ""
        await store.dispatch('session/fetchToken').then((response) => { token = response })
        const authToken = "Bearer " + token
        return this.submit(`stax`, authToken, { client });
    }

    async calcStax(order: any) {
        let token = ""
        await store.dispatch('session/fetchToken').then((response) => { token = response })
        const authToken = "Bearer " + token
        return this.submitPost(`calcstax`, authToken, order );
    }

    private async submit(endpoint: string, token: string, params: any) {
        return new Promise((resolve, reject) => {
            this.client
                .get(endpoint, {
                    params,
                    headers:
                    {
                        'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
                        'Authorization': token
                    }
                })
                .then((response) => {
                    resolve(response.data.stax_items);
                })
                .catch(error => reject(error))
        });

    }

    private async submitPost(endpoint: string, token: string, payload: any, params?: any) {
        return new Promise((resolve, reject) => {
          this.client.post(endpoint, payload, { 
            params,
            headers:
            {
                'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
                'Authorization': token
            }
           })
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        })
      }
    

}
