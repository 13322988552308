import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import ControlService from "@/services/ControlService";
import StaxService from "@/services/StaxService";
import ControlState from "@/types/state/control";
import CategoryItem from "@/types/categoryItem";

const namespaced = true;
const controlService = new ControlService(process.env.VUE_APP_ABSTRACTION_API);
const staxService = new StaxService(process.env.VUE_APP_ABSTRACTION_API);

export const state: ControlState = {
  price: {
    code_items: null
  },
  mrk: {
    hold_code_items: null
  },
  plan: {
    plan_group_items: null
  },
  company: {
    code_items: null
  },
  ar: {
    ar_type_name_items: null
  },
  terms: [],
  categoryItems:[],
  filterItems: [],
  codeItems: [],
  coCodes: null,
  selectedCoCode: null,
  CountryControls: [],
  shipvia: null,
  stax: null,
  controlFlags: null,
  cartQuotePriceModel: null,
  ccFee: null,
  achFee: null,
};

export const getters: GetterTree<ControlState, RootState> = {
  getPrice: (state) => {
    return state.price;
  },
  getMRK: (state) => {
    return state.mrk;
  },
  getAR: (state) => {
    return state.ar;
  },
  getPlan: (state) => {
    return state.plan;
  },
  getCompany: (state) => {
    return state.company;
  },
  getCategoryItems(state) {
    return state.categoryItems;
  },
  getFilterItems(state) {
    return state.filterItems;
  },
  getCompanyCodes(state) {
    return state.codeItems;
  },
  getSelectedCoCode: (state) => {
    return state.selectedCoCode;
  },
  getCountries: (state) => {
    return state.CountryControls;
  },
  getShipVia: (state) => {
    return state.shipvia;
  },
  getStax: (state) => {
    return state.stax;
  },
  getControlFlags: (state) => {
    return state.controlFlags;
  },
  getCartQuotePriceModel: (state) => {
    return state.cartQuotePriceModel;
  },
  getACHFee: (state) => {
    return state.achFee;
  },
  getCCFee: (state) => {
    return state.ccFee;
  },
};

export const mutations: MutationTree<ControlState> = {
  SET_PRICE(state, price) {
    state.price = price;
  },
  SET_MRK(state, codes) {
    state.mrk = codes || []
  },
  SET_AR(state, codes) {
    state.ar = codes || []
  },
  SET_PLAN(state, codes) {
    state.plan = codes;
  },
  SET_COMPANY(state, codes) {
    state.company = codes;
  },
  SET_POS_CATS(state, catData) {
    state.categoryItems = catData
  },
  SET_POS_FILTERS(state, filterData) {
    const mutated = [] as any
    filterData.forEach((tag:any) => {
      if(tag.parent_category) {
        if(!mutated.some((cat: any) => { return cat.category === tag.parent_category})) {
          mutated.push({category: tag.parent_category, label: tag.parent_category, tags: [{label: tag.web_category_title, tag: tag.web_category}]})
        } else {
          const index = mutated.findIndex((cat:any) => {
            return cat.category === tag.parent_category;
          });
          if(!mutated[index].tags) {
            mutated[index].tags = []
          }
          mutated[index].tags.push({label: tag.web_category_title, tag: tag.web_category})
        }
      }
      else {
        if(mutated.some((cat: any) => { return cat.category === tag.web_category})) {
          const index = tag.findIndex((cat:any) => {
            return cat.category === tag.web_category;
          });
          mutated[index].label = tag.web_category_title || tag.web_category
        }
        else {
          mutated.push({category: tag.web_category, label: tag.web_category_title || tag.web_category})
        }
      }
    });
    const noEmptyCats = mutated.filter((cat:any) => cat.tags !== null && cat.tags !== undefined)
    state.filterItems = noEmptyCats
  },
  SET_COMPANY_CODE_ITEMS(state, items) {
    state.codeItems = items;
  },
  SET_SELECTED_CO_CODE(state, code) {
    state.selectedCoCode = code;
  },
  SET_SHIPVIA(state, shipvia) {
    state.shipvia = shipvia;
  },
  PUSH_COUNTRY(state, countries) {
    state.CountryControls = countries;
  },
  SET_STAX(state, stax) {
    state.stax = stax;
  },
  SET_CONTROL_FLAGS(state, controlFlags) {
    state.controlFlags = controlFlags;
  },
  SET_CART_QUOTE_MODEL(state, cartQuotePriceModel) {
    state.cartQuotePriceModel = cartQuotePriceModel;
  },
  SET_CC_FEE(state, ccFee) {
    state.ccFee = ccFee;
  },
  SET_ACH_FEE(state, achFee) {
    state.achFee = achFee;
  },
};

export const actions: ActionTree<ControlState, RootState> = {
  setFilters({commit}, filters) {
    commit("SET_POS_FILTERS", filters || []);
  },  
  setSelectedCoCode({ commit }, code) {
    commit("SET_SELECTED_CO_CODE", code);
  },
  getStax({ commit }, { client }) {
    staxService.getStax(client).then((response) => {
      commit("SET_STAX", response);
    });
  },
  fetchControls({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.fieldnames == null) {
        payload.fieldnames = "";
      }
      controlService
        .getControl(
          payload.id,
          payload.procedure,
          payload.filename,
          payload.fieldnames
        )
        .then((response: any) => {
          switch (payload.id) {
            case "PRICE":
              if (!response[0]) {
                commit("SET_PRICE", []);
                break;
              } else {
                commit("SET_PRICE", response[0].code_items);
                break;
              }
            case "MRK":
              if (!response[0]) {
                commit("SET_MRK", []);
              } else {
                commit("SET_MRK", response[0].hold_code_items);
              }
              break;
            case "PLAN":
              if (!response[0]) {
                commit("SET_PLAN", []);
              } else {
                commit("SET_PLAN", response[0].plan_group_items);
              }
              break;
            case "CAT":
              commit("SET_POS_CATS",  response[0].category_items);
              break;
            case "QUOTE":
              commit("SET_CART_QUOTE_MODEL",  response[0].quote_to_cart_model);
              break;
            case "WEB":
              commit("SET_POS_FILTERS", response[0]?.web_category_items || []);
              commit("SET_CONTROL_FLAGS", response[0]?.control_flag_items || []);
              break;
            case "COMPANY":
              commit("SET_COMPANY_CODE_ITEMS", response[0].code_items);
              break;
              default:
              break;
            case "CC":
              commit("SET_CC_FEE", response[0]?.conv_fee_pct);
              break;
            case "AR1":
              if (!response[0]) {
                commit("SET_AR", []);
              } else {
                commit("SET_ACH_FEE", response[0]?.conv_fee_pct);
                commit("SET_AR", response[0]?.ar_type_name_items);
              }
              break;
            case "SHIP":
                if (!response[0]) {
                  commit("SET_SHIPVIA", []);
                  commit("PUSH_COUNTRY", []);
                }
                if (response[0] && response[0].ship_via_items) {
                  commit("SET_SHIPVIA", response[0].ship_via_items);
                }
                if (response[0] && response[0].country_items) {
                  commit("PUSH_COUNTRY", response[0].country_items);
                }
                break;
          }
          resolve({ message: "success" });
        });
    });
  },
};

export const control: Module<ControlState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
