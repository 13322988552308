import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import PrintableDatatableState from "@/types/state/printableDatatable";

const namespaced = true;

export const state: PrintableDatatableState = {
  orders: null,
  defaultColumns: [],
  availableColumns: [],
  exportName: "export.csv",
};

export const getters: GetterTree<PrintableDatatableState, RootState> = {
  getOrders: (state) => {
    return state.orders;
  },
  getDefaultColumns: (state) => {
    return state.defaultColumns;
  },
  getAvailableColumns: (state) => {
    return state.availableColumns;
  },
  getExportFilename: (state) => {
    return state.exportName;
  },
};

export const mutations: MutationTree<PrintableDatatableState> = {
  SET_ORDER_DATA(state, orders) {
    if (state.orders) {
      state.orders = [...state.orders, ...orders];
    } else {
      state.orders = [...orders];
    }
  },
  SET_DEFAULTCOLUMN_DATA(state, defaultColumns) {
    state.defaultColumns = defaultColumns;
  },
  SET_AVAILABLECOLUMN_DATA(state, availableColumns) {
    state.availableColumns = availableColumns;
  },
  SET_EXPORTFILENAME(state, exportFileName) {
    state.exportName = exportFileName;
  },
  CLEAR_ORDERS(state) {
    state.orders = null;
  },
  CLEAR_DYNAMICCOLUMNS(state) {
    state.defaultColumns = [];
  },  

};

export const actions: ActionTree<PrintableDatatableState, RootState> = {
  setData({ commit }, orders) {
    commit("SET_ORDER_DATA", orders);
  },
  clearData({ commit }) {
    commit("CLEAR_ORDERS");
  },
  setDefaultColumns({ commit }, defaultColumns) {
    commit("SET_DEFAULTCOLUMN_DATA", defaultColumns);
  },
  setAvaialbleColumns({ commit }, availableColumns) {
    commit("SET_AVAILABLECOLUMN_DATA", availableColumns);
  },
  setExportFilename({ commit }, exportFilename) {
    commit("SET_EXPORTFILENAME", exportFilename);
  },
};

export const printableDatatable: Module<PrintableDatatableState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

