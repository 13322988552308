import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store"
import qs from "qs";

export default class PartsService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getParts(fieldnames: string, client: string) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    return this.submit(`parts`, authToken, { fieldnames: fieldnames, Client: client });
  }

  async getPartsRecords(
    client: string,
    rangeStart: number,
    rangeEnd: number,
    type: string,
    Id: string
  ) {

    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    return this.submit(`parts`, authToken, {
      Client: client,
      rangeStart: rangeStart,
      rangeEnd: rangeEnd,
      type: type,
      Id: Id,
    });
  }
  async getPrice(part: string, qty: string, code: string, cust: string, date: string) {

    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token

    const params = {
      qty,
      code,
      cust,
      date,
    };
    return this.submit(`price/${part}`, authToken, params);
  }

  async getPrices(client: string, part_no: string, cat: string, code: string, web_cats: string, customerId: string, rangeStart?: string, rangeEnd?: string) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token

    const params = {
      client,
      part_no,
      cat,
      code,
      web_cats: web_cats,
      customerId,
      rangeStart,
      rangeEnd,
    };

    return this.submit(`prices`, authToken, params);
  }

  async getPartsBySelection(selection: string, fieldnames?: any,) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    return this.submit(`parts`, authToken, { fieldnames: fieldnames, selection });
  }

  async fetchPartsOptions(selection: string) {

    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token

    const params = {
      selection,
    };
    return this.submit("parts", authToken, params);
  }

  async getPart(id: string, customerId?: string, correls?: string) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    const params = {customerId, correls}
    return this.submit(`part/${id}`, authToken, params);
  }
  
  private async submit(endpoint: string, token: string, params?: any) {
    return new Promise((resolve, reject) => {
      this.client
        .get(endpoint, { 
          params,
          paramsSerializer: params => {
            return qs.stringify(params)
          },
          headers:
          {
              'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
              'Authorization': token
          }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {console.log(error); reject(error);});
    });
  }
}
