import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import TermsState from "@/types/state/terms";

import TermsService from "@/services/TermsService";

const termsService = new TermsService(process.env.VUE_APP_ABSTRACTION_API);

const namespaced = true;

export const state: TermsState = {
    terms: []
  };

  export const getters: GetterTree<TermsState , RootState> = {
    getTerms: (state) => {
      return state.terms;
    },
  };
  export const mutations: MutationTree<TermsState> = {
    SET_TERMS(state, { terms }) {
      state.terms = terms;
    },
  };
  export const actions: ActionTree<TermsState, RootState> = {
    async fetchTerms({ commit }) {
      await termsService.getTerms().then((response: any) => {
        if (response) {
          commit("SET_TERMS", { terms: response.data });
        }
      });
    },
};
  export const terms: Module<TermsState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
  };

  