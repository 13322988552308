import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { RootState } from "@/types/state";
import CustomerState from "@/types/state/customer";
import CustomerService from "@/services/CustomerService";

const namespaced = true;
const service = new CustomerService(process.env.VUE_APP_ABSTRACTION_API);
export const state: CustomerState = {
  customer: null,
};

export const getters: GetterTree<CustomerState, RootState> = {
  getCust(state) {
    return state.customer;
  },
};

export const actions: ActionTree<CustomerState, RootState> = {
  setCustomer({commit}, customer) {
    commit("SET_CUSTOMER", customer);
  },
   fetchCustomer({ commit, dispatch, state }, { id, correls}) {
    return new Promise((resolve, reject) => {
      service
        .getCustomer(id, "", correls)
        .then((response: any) => {
          commit("SET_CUSTOMER", response);
          resolve("true");
        })
        .catch((error: any) => {
          const notification = {
            type: "error",
            message: error,
          };
          dispatch("notification/add", notification, { root: true });
          reject(error);
        });
    });
  },
  updateCustomer({ commit, state, dispatch }, { id, client, newCust, oldCust }) {
    return new Promise((resolve, reject) => {
      service
        .putCustomer(id, client, newCust, oldCust)
        .then((response) => {
          const notification = {
            type: "success",
            message: "Customer information saved",
          };
          dispatch("notification/add", notification, { root: true });
          commit("SET_CUSTOMER", newCust);
          resolve(response);
        })
        .catch((error) => {
          const notification = {
            type: "error",
            message: error,
          };
          dispatch("notification/add", notification, { root: true });
          reject(error);
        });
    });
  },
};

export const mutations: MutationTree<CustomerState> = {
  SET_CUSTOMER(state, cust) {
    state.customer = cust;
  },
};

export const customer: Module<CustomerState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
