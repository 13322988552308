import { AxiosInstance } from "axios";
import axios from "axios";

export default class AddressValidation {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async fetchToken() {
    return this.submitPost("token");
  }

  private async submitPost(endpoint: string) {
    return new Promise((resolve, reject) => {
      this.client
        .post(endpoint,)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  }
}
